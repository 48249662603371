import '../styles/faq.scss';

import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import DownArrow from '../images/DownArrow.jpeg';
import UpArrow from '../images/UpArrow.jpeg';

function FaqPage() {

  const onClick_section = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick_section ' + event.target.name );
    if ( !showSections[index] ) {
      setShowSections( { ...showSections, [index]: true } );
    }
    else {
      setShowSections( { ...showSections, [index]: false } );
    }
  };

  const onClick = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick ' + event.target.name, showResults[index] );
    if ( !showResults[index] ) {
      setShowResults( { ...showResults, [index]: true } );
    }
    else {
      setShowResults( { ...showResults, [index]: false } );
    }
  };

  const [ showSections, setShowSections ] = React.useState( { 1: true } );
  const [ showResults, setShowResults ] = React.useState( {} );

  return (
    <Layout header={'menu'}>
      <SEO
        title="Get answers related to Life &amp; Home Insurance Queries "
        description="Our FAQ section will help you get answers to the most frequently asked questions, related to Life and Home Insurance. Visit now!"
        canonicalUrl='https://www.getmybubble.io/faq'
        robots={ { 1:'index', 2: 'follow' } }
      />

      <div id="faq" className="wrapper">
        <div className="flexColumn carrierCommonPage">
          <div className="company-info-container container-fluid">
            <div className="row">
              <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                  <h1 className="carrier-plan-heading section70" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                                    Frequently Asked Questions
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container faq-margin-bottom common-container-space">
            <div className="row">
              <div className="col-lg-12">
                {/*About Home Insurance*/}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="faqFlex bg-white-border">
                      <div className="faqLeftContainer faq-left-container">
                        <div className="col-sm-12" onClick={( e ) => onClick_section( e, 1 )}>
                          <div className="align-faq-que position-relative">
                            <div className="faq-section-headings">
                                                                Premium Payments
                            </div>

                            <img
                              src={showSections[1] ? UpArrow : DownArrow}
                              className="faq-dd m-0" name="1" alt="Bubble Life Insurance"/></div>
                        </div>

                        <div className="col-12 both-space-padding">
                          <div className="row">
                            {showSections[1] && <>
                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 3 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Will climate change affect
                                                                                                my
                                                                                                home insurance premium? </div><img src={showResults[3] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="11" alt="Bubble Life Insurance" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 4 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Does my home insurance
                                                                                                premium increase at renewal? </div><img src={showResults[4] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="12" alt="Bubble Life Insurance" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 5 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Is it true that women pay a
                                                                                                lower premium than men? </div><img src={showResults[5] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="30" alt="Bubble Life Insurance" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 6 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> How can I lower my home
                                                                                                insurance premium? </div><img src={showResults[6] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="31" alt="Bubble Life Insurance" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 7 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Will my shed in the
                                                                                                backyard
                                                                                                and its contents be covered in my home insurance policy? </div><img
                                            src={DownArrow} className="faq-dd faq-dd-width m-0" name="32"
                                            alt="Bubble Life Insurance" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 8 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What can affect my life
                                                                                                insurance premium? </div><img src={showResults[8] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="41" alt="Bubble Life Insurance" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 9 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Will my life insurance
                                                                                                premium increase with time? </div><img src={showResults[9] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="46" alt="Bubble Life Insurance" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*End of Home Insurance */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default FaqPage;
